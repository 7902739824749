import { domReady, getAttribute, qs, qsa, removeAttribute, setAttribute, } from 'lib/dom.js'
import { off, on } from 'lib/events.js'
import { swap } from 'lib/transition.js'

function sidenavTransitionEnd(transitionEvent) {
	if (transitionEvent.propertyName === 'visibility') {
		off(document.body, 'transitionend', sidenavTransitionEnd)

		qsa('#sidenav, #sidenavOverlay').forEach(elm => setAttribute(elm, 'hidden', ''))
	}
}

domReady(_ => {
	qsa('[data-toggle-sidenav]').forEach(toggle => {
		on(toggle, 'click', _ => {
			if (document.body.classList.contains('sidenavActive')) {
				on(document.body, 'transitionend', sidenavTransitionEnd)
				document.body.classList.remove('sidenavActive')
			} else {
				qsa('#sidenav, #sidenavOverlay').forEach(elm => removeAttribute(elm, 'hidden'))

				document.body.offsetHeight /* Force a reflow so our animation works. */
				document.body.classList.add('sidenavActive')
			}
		}, { passive: true })
	})

	qsa('.tabbed-navigation').forEach(tabbedNavigation => {
		const controls = qsa('button[aria-controls]', tabbedNavigation)

		controls.forEach(control => {
			on(control, 'click', _ => {
				const outControl = controls.find(c => getAttribute(c, 'aria-selected') === 'true')
				const outContent = qs(`#${ getAttribute(outControl, 'aria-controls') }`)
				const inContent = qs(`#${ getAttribute(control, 'aria-controls') }`)

				swap(outContent, inContent, _ => {
					setAttribute(outControl, 'aria-selected', 'false')
					setAttribute(control, 'aria-selected', 'true')
				})
			}, { passive: true })
		})
	})
})
